@use "variables";

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: local(""), url("/assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    src: local(""), url("/assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: local(""), url("/assets/fonts/IBMPlexSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    src: local(""), url("/assets/fonts/IBMPlexSans-Bold.ttf") format("truetype");
}

p {
    font-family: "IBM Plex Sans", sans-serif;
    color: variables.$adn-color-primary;
}

a:hover {
    text-decoration: none;
    color: variables.$adn-color-brand;
}

p.adn-legal-text {
    font-size: 12px;
}

.adn-compact-table>tr>td {
    border: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
}

.adn-hidden {
    display: none;
}

.app-panel {
    margin: 16px;
}

.adn-main-content {
    margin-top: 64px;
}

.adn-main-content-outlet-hidden {
    display: none;
}

.adn-buttons-horizontal {
    display: block;
}

.adn-buttons-horizontal>.adn-material-button {
    display: inline-block;
    margin: 4px;
}

.adn-card-section-divider {
    margin-top: 16px;
}

.adn-dropdwon-active {
    background-color: lightgrey;
}

.adn-dropdwon-inactive {
    background-color: transparent;
}

.app-panel-header {
    margin-top: 1em;
    margin-bottom: 1em;
    word-break: break-word;
}

.help-popup-button {
    color: variables.$adn-color-primary;
    display: inline-block;
    position: relative;
}

.adn-measure-unit {
    color: variables.$adn-color-primary;
    font-size: smaller;
}

.adn-right-aligned {
    flex-grow: 0;
}

/* Form */

.adn-control-label {
    display: inline-block;
    color: variables.$adn-color-primary-brighter;
    font-size: 12px;
    margin-bottom: 0px;
}

.adn-control-static {
    border: 1px dotted variables.$adn-color-bright-border;
    padding: 6px 12px;
    min-height: 2em;
    border-radius: 4px;
}

/* Menu */

.adn-menu-header {
    font-family: "IBM Plex Sans", sans-serif !important;
    font-size: 30px;
    font-weight: bold;
    border-bottom: 3px solid variables.$adn-color-brand;
}

.adn-menu-initials-block {
    margin-right: 14px;
}

.adn-menu-user-arrow {
    margin-left: 2px;
    margin-top: -7px;
    vertical-align: middle;
}

.adn-admin-button-margin {
    margin-left: 15px !important;
}

.adn-menu-icons {
    padding-right: 32px;
}

.adn-menu-booking-btn {
    padding-right: 8px;
}

.adn-menu-flags {
    margin-right: 12px;
    margin-top: -3px;
    box-shadow: 0 0 5px rgba(variables.$adn-color-primary-darker, 0.2);
}

.adn-menu-divider {
    width: 32px;
    border-right: 1px solid variables.$adn-color-divider;
    height: 34px;
    margin-right: 32px;
}

.adn-user-menu-item {
    background-color: variables.$adn-color-white !important;
    color: variables.$adn-color-primary !important;
}

.adn-user-menu-item:hover {
    color: $adn-color-primary !important;
    background-color: $adn-color-background !important;
}

.adn-user-menu-item-active {
    background-color: variables.$adn-color-white !important;
    color: variables.$adn-color-brand !important;
}

.adn-user-menu-item-active:hover {
    color: $adn-color-primary !important;
    background-color: $adn-color-background !important;
}

.adn-party-menu-item {
    background-color: variables.$adn-color-white !important;
    color: variables.$adn-color-primary !important;
}

.adn-party-menu-item:hover {
    color: $adn-color-primary !important;
    background-color: $adn-color-background !important;
}

.adn-party-menu-item-active {
    background-color: variables.$adn-color-white !important;
    color: variables.$adn-color-brand !important;
}

.adn-shipment-menu-item-active {
    color: variables.$adn-color-brand !important;
}

.adn-party-menu-item-active:hover {
    color: $adn-color-primary !important;
    background-color: $adn-color-background !important;
}

.adn-admin-menu-item {
    background-color: variables.$adn-color-brand !important;
    color: variables.$adn-color-white !important;
}

.adn-admin-menu-item:hover {
    color: $adn-color-primary !important;
    background-color: $adn-color-background !important;
}

.adn-admin-menu-item-active {
    background-color: variables.$adn-color-background !important;
    color: variables.$adn-color-primary !important;
}

.adn-admin-menu-item-active:hover {
    color: $adn-color-primary !important;
    background-color: $adn-color-background !important;
}

.menu-button-indent-level1 {
    padding-left: 2.2rem !important;
}

/* Remove focus ring on buttons */

button:focus {
    outline: none !important;
}

.mat-dialog-actions {
    justify-content: flex-end;
}

.adn-menu-indent {
    margin-left: 16px;
}

.full-width {
    width: 100%;
}

/* Colors */

.adn-color-disabled {
    color: variables.$adn-color-disabled;
}

.adn-color-alert-brighter {
    color: variables.$adn-color-alert-brighter !important;
}

.no-border {
    border: none;
}

.adn-mode-unknown {
    border-bottom: 2px solid variables.$adn-color-disabled;
}

.adn-mode-sea {
    border-bottom: 2px solid #21d3ff;
}

.adn-mode-rail {
    border-bottom: 2px solid #009f99;
}

.adn-mode-road {
    border-bottom: 2px solid variables.$adn-color-earth;
}

.adn-color-background-alert-brighter {
    background-color: variables.$adn-color-alert-brighter;
}

.adn-color-alert {
    color: variables.$adn-color-alert !important;
}

.adn-color-green {
    color: variables.$adn-color-green;
}

.adn-color-white {
    color: variables.$adn-color-white !important;
}

.adn-color-primary {
    color: variables.$adn-color-primary !important;
}

.adn-color-accent-darker {
    color: variables.$adn-color-accent-darker !important;
}

.adnavem-white-background-color {
    background-color: transparent !important;
}

.adnavem-confirm-background-color {
    background-color: variables.$adn-color-accent !important;
}

.adnavem-primary-background-color {
    background-color: variables.$adn-color-brand !important;
}

.adnavem-text-color-with-confirm-background {
    background-color: variables.$adn-color-accent !important;
    color: variables.$adn-color-white !important;
}

.adnavem-text-color-with-primary-background {
    background-color: variables.$adn-color-brand !important;
    color: variables.$adn-color-white !important;
}

.adnavem-primary-with-light-background {
    background-color: variables.$adn-color-background !important;
    color: variables.$adn-color-brand !important;
}

.adnavem-dark-text-with-light-background {
    background-color: variables.$adn-color-background;
    color: variables.$adn-color-primary;
}

.adnavem-public-menuitem-color {
    color: variables.$adn-color-primary !important;
}

/* Set material stroked buttons to 2px border */

.adnavem-outlined-button {
    border-style: solid !important;
    border-width: 2px !important;
    border-color: currentColor !important;
}

.network-icon-blink {
    color: variables.$adn-color-white;
    animation: blink 0.7s infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0.25;
    }

    50% {
        opacity: 0.5;
    }

    75% {
        opacity: 0.75;
    }

    100% {
        opacity: 1;
    }
}

path.logo {
    fill: variables.$adn-color-brand;
}

polygon.logo {
    fill: variables.$adn-color-brand;
}

.cell-control {
    padding: 0px !important;
    margin: 0px !important;
    vertical-align: middle !important;
}

.cell-control>input {
    width: 100%;
}

.form-control {
    font-size: 16px !important;
}

.state-deleted {
    text-decoration: line-through !important;
    text-decoration-color: variables.$adn-color-alert;
}

.state-duplicate {
    color: variables.$adn-color-alert;
}

.state-modified {
    font-weight: bold;
}

.adn-credit-score {
    font-weight: bold;
    font-size: larger;
    text-align: center;
}

section {
    padding: 10px;
    max-width: 400px;
    margin: auto;
}

.nav li.active a {
    background-color: variables.$adn-color-brand;
    color: variables.$adn-color-white;
}

/* You can add global styles to this file, and also import other style files */

.header-underlines {
    border-bottom: 3px solid variables.$adn-color-brand;
}

/* Tabs */

.nav-tabs>li {
    height: 48px;
    padding: 0 24px;
    cursor: pointer;
    box-sizing: border-box;
    opacity: 0.6;
    min-width: 160px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.nav-tabs>li.active {
    opacity: 1;
    border-bottom: variables.$adn-color-primary-darker 2px solid;
}

.adn-tab-content {
    margin-top: 8px;
}

div.loader {
    z-index: 100;
    font-size: 160px;
    text-align: center;
    color: variables.$adn-color-brand;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: variables.$adn-color-background;
}

div.mini-loader {
    font-size: 56px;
    text-align: center;
    color: variables.$adn-color-brand;
}

.form-control-static {
    border: 1px dotted variables.$adn-color-bright-border;
    padding: 6px 12px;
    border-radius: 4px;
}

.has-feedback .form-control-feedback {
    position: absolute;
    top: 0px;
    right: 15px;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
}

.adnavem-header-1 {
    background-color: transparent;
    border-radius: 0px !important;
    font-weight: 500;
    font-size: 24px;
    color: variables.$adn-color-primary;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.btn {
    border-radius: 0px !important;
}

.adn-cell-buttons {
    margin: 0px !important;
    padding: 2px !important;
    white-space: nowrap;
}

.adn-cell-buttons ul {
    margin: 0px !important;
    padding: 0px !important;
}

.adn-cell-button {
    -webkit-tap-highlight-color: transparent;
    background-color: variables.$adn-color-white;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    display: inline-block;
    line-height: 26px;
    margin: 0 2px 0 0;
    min-width: 32px;
    outline: none;
    overflow: visible;
    padding: 0 8px;
    text-align: center;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    vertical-align: baseline;
    white-space: nowrap;
}

.adn-alert {
    background-color: variables.$adn-color-alert;
    color: variables.$adn-color-primary;
}

.adn-accent {
    background-color: variables.$adn-color-primary;
    color: variables.$adn-color-primary;
}

.adn-cell-buttons button {
    display: inline;
}

.navbar-brand {
    margin: 0px;
    padding: 9px !important;
}

.logo {
    width: 111px;
    height: 24px;
}

.list-compact li {
    padding: 0px !important;
}

.centered-maxwidth-container {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    max-width: 1100px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.mat-form-field {
    width: 100%;
}

.centered-maxwidth-container div.row {
    margin-left: -4px;
    margin-right: -4px;
}

.centered-maxwidth-container>.row>[class^="col-"],
.centered-maxwidth-container>.row>[class*=" col-"] {
    padding-left: 4px;
    padding-right: 4px;
}

.adn-nav-container {}

app-core-mega-search {
    padding-right: 12px;
    width: 140px;
}

.adn-nav-sidebar {
    display: none;
}

.adn-nav-content {
    padding: 1px 4px;
}

.adn-text-truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adn-shipment-steps-container {
    width: 100%;
    border-collapse: collapse;
}

.adn-shipment-progress-step {
    text-align: center;
    padding: 0;
    margin: 0;
}

.adn-top-bottom-space {
    margin-top: 0;
    margin-bottom: 0;
}

.adn-schedules-label {
    font-size: small;
    color: variables.$adn-color-label-dimmed;
}

.adn-schedules-header-value {
    font-weight: 700;
}

.search-info-container {
    background-color: variables.$adn-color-white;
    padding: 15px 30px;
    margin-left: -15px;
    margin-right: -15px;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(variables.$adn-color-primary, 0.15);
    box-shadow: 0px 2px 8px 0px rgba(variables.$adn-color-primary, 0.15);
}

.search-info-container .row [class*="col-"] {
    border: 1px solid rgba(variables.$adn-color-primary, 0.15);
    margin-bottom: 10px;
    margin-right: -1px;
    text-align: center;
    color: variables.$adn-color-primary;
    font-size: 16px;
    padding: 10px 14px 10px 15px;
}

/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) and (display-mode: browser) {
    .adn-nav-sidebar {
        display: initial;
        float: left;
        margin: 0;
        padding: 0;
        width: 240px;
        position: fixed;
        z-index: 998;
        overflow-y: auto;
        max-height: calc(100vh - 64px);
    }

    .adn-nav-content {
        margin-left: 240px;
        padding: 1px 16px;
    }

    .contain-width-limit {
        max-width: 500px;
        margin: 0 auto;
    }

    .contain-height-limit {
        max-height: 360px !important;
    }

    .adn-content-background.login-form-min-height {
        min-height: min(35vw, 560px);
    }
}

@media only screen and (min-width: 992px) and (display-mode: fullscreen) {
    .app-panel-title {
        display: none;
    }

    .app-panel-help {
        display: none;
    }

    .adn-nav-content {
        padding: 1px 1px;
    }

    .app-panel-header {
        visibility: collapse;
        display: none;
    }
}

@media only screen and (min-width: 992px) and (display-mode: standalone) {
    .adn-nav-sidebar {
        display: initial;
        float: left;
        margin: 0;
        padding: 0;
        width: 240px;
    }

    .adn-nav-content {
        margin-left: 240px;
        padding: 1px 16px;
    }
}

@media only screen and (max-width: 480px) {
    div.nopadding>div.card-body {
        padding: 0px;
    }

    div.app-panel {
        margin: 2px;
    }
}

/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) {

    .mat-toolbar-row,
    .mat-toolbar-single-row {
        height: 64px !important;
    }
}

/* Extra Small Devices, Phones */

@media only screen and (min-width: 480px) {

    .mat-toolbar-row,
    .mat-toolbar-single-row {
        height: 64px !important;
    }
}

@media (max-width: 375px) {

    .adn-content h1,
    .h1 {
        font-size: 2rem;
    }

    .adn-content h2,
    .h2 {
        font-size: 1.85rem;
    }
}

@media (max-width: 991px) {
    .adn-content h1 {
        text-transform: capitalize !important;
    }

    .adn-content-dark-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .adn-content-background.login-form-min-height {
        min-height: inherit;
    }

    .cta div.cta-list {
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
    }
}

/* Small Devices, Tablets */

@media only screen and (min-width: 768px) {

    .mat-toolbar-row,
    .mat-toolbar-single-row {
        height: 64px !important;
    }

    .logo {
        width: 111px;
        height: 24px;
    }

    .content h1 {
        font-size: 40px;
    }

    section {
        padding: 60px;
        max-width: 1024px;
        margin: auto;
    }

    .background-container {
        min-height: 62vh;
        height: 100%;
        padding-bottom: 2em;
    }
}

/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {

    .mat-toolbar-row,
    .mat-toolbar-single-row {
        height: 64px !important;
    }

    .logo {
        width: 111px;
        height: 24px;
    }

    .content h1 {
        font-size: 52px;
    }
}

/* Medium Devices, Tablet to Mobile */
@media only screen and (max-width: 991px) and (min-width: 375px) {
    .contain-width-limit {
        max-width: 500px;
        margin: 0 auto;
    }
}

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {

    .mat-toolbar-row,
    .mat-toolbar-single-row {
        height: 64px !important;
    }

    .content h1 {
        font-size: 64px;
    }

    .background-container {
        min-height: 62vh;
        height: 100%;
        padding-bottom: 2em;
    }

    .adn-content-background.login-form-min-height {
        min-height: max(33vw, 560px) !important;
    }
}

/* Hide when Screen Size smaller than 1200px */
@media only screen and (max-width: 1199px) {
    .mobile-tablet-hide {
        display: none;
    }
}

/* Extra Large Devices, Ultra Wide Screens */

@media only screen and (min-width: 1680px) {
    .adn-content-background.login-form-min-height {
        min-height: min(33vw, 560px) !important;
    }
}

.my-drop-zone {
    border: dotted 3px lightgray;
}

.nv-file-over {
    border: dotted 3px variables.$adn-color-brand;
}

/* Material */

.fa-material {
    font-size: 1.3em;
}

.party-icon {
    width: 32px;
    height: 32px;
}

.party-icon-small {
    width: 24px;
    height: 24px;
    margin: 2px;
}

.floating-top-right {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    margin-top: 80px !important;
    margin-right: 1em !important;
    z-index: 81 !important;
}

.floating-bottom {
    position: fixed;
    bottom: 0 !important;
    z-index: 81 !important;
}

.material-floating-main-button {
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    margin-bottom: 1em !important;
    margin-right: 1em !important;
    z-index: 81 !important;
}

.loading-spinner {
    position: fixed !important;
    bottom: 1em !important;
    left: 1em !important;
    z-index: 81 !important;
    margin-bottom: 0em !important;
    margin-left: 0em !important;
    padding-bottom: 0em !important;
    padding-left: 0em !important;
}

.clickable {
    cursor: pointer;
}

.alert-container {
    margin-top: 92px !important;
    background-color: variables.$adn-color-white !important;
    color: variables.$adn-color-primary-darker;
    border-bottom: 4px solid variables.$adn-color-white;
    border-bottom-style: inset;
    box-shadow: 0px 3px 5px 4px rgba(variables.$adn-color-primary-darker, 0.2);
}

.alert-container.success {
    border-bottom: 4px solid variables.$adn-color-green;
}

.alert-container.info {
    border-bottom: 4px solid variables.$adn-color-accent-darker;
}

.alert-container.error {
    border-bottom: 4px solid variables.$adn-color-alert;
}

/** Inline Notification **/
.notification-icon {
    display: inline-block;
    position: absolute;
    transform: translate(0px, 5px);
}

.notification-message {
    display: inline-block;
    padding-left: 25px;
    width: inherit;
}

.schedule-table {
    font-size: 10px !important;
}

.material-action-button-right {
    bottom: 0 !important;
    right: 0 !important;
    margin-right: 1em !important;
    margin-bottom: 1em !important;
    z-index: 81 !important;
    position: fixed !important;
}

.material-table {
    overflow: auto;
    min-height: 300px;
}

.adn-rounded-border {
    border-radius: 4px;
}

.mat-card-row-margin {
    margin-bottom: 0.5em;
}

mat-header {
    flex: 1;
}

.side-nav {
    padding: 1em;
}

.side-nav-content {
    padding: 1em;
}

.small-form {
    width: 350px;
}

.adn-header {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    min-height: 64px;
    margin: 0;
    letter-spacing: normal;
    background-color: variables.$adn-color-primary;
}

.adn-header-row {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    padding: 0 16px;
    height: 64px;
    width: 100%;
    align-items: center;
    white-space: nowrap;
}

.adn-public-header {
    background-color: variables.$adn-color-white !important;
}

.sticky-header {
    position: fixed;
    z-index: 999;
    top: 0;
}

.adn-public-menu {
    flex-wrap: wrap;
    overflow: auto;
}

.app-binary-content {
    padding-top: 64px !important;
}

.left-box {
    width: 50%;
    padding-top: 21px;
}

.right-box {
    width: 50%;
    padding-top: 12px;
}

/*Content Container*/

.content-container {
    padding: 0;
    margin-top: 40px;
    margin-bottom: 40px;
}

@media (min-width: 1200px) {
    .content-container {
        padding: 0 30px;
    }
}

app-site-footer {
    margin-top: 32px;
    padding-top: 64px;
    padding-bottom: 64px;
    background-color: variables.$adn-color-background;
}

/* end footer */

.card-margin {
    margin-bottom: 1em;
}

.row-margin {
    margin-bottom: 1em;
}

.margin-bottom-large {
    margin-bottom: 2rem;
}

.clickable {
    cursor: pointer;
}

.card-flex {
    display: flex !important;
}

.checkbox-margin {
    margin-right: 1em;
}

.row-without-margin {
    display: flex;
    flex-wrap: wrap;
}

.col-padding {
    padding-left: 15px;
    padding-right: 15px;
}

.button-row-margin {
    margin-left: 0.5em !important;
}

.container-no-gutters {
    margin-right: auto;
    margin-left: auto;
}

.container-no-gutters>.row {
    margin-right: 0;
    margin-left: 0;
}

.container-no-gutters>.row>[class^="col-"],
.container-no-gutters>.row>[class*=" col-"] {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

/* Please clean us up */

.navigation-buttons {
    margin-top: 1em;
    margin-bottom: 1em;
}

/* table */

.table thead th {
    vertical-align: bottom !important;
    border-bottom: 3px solid variables.$adn-color-primary-brighter !important;
}

.table tfoot tr:first-child {
    vertical-align: bottom !important;
    border-top: 3px solid variables.$adn-color-primary-brighter !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: variables.$adn-color-table-striped !important;
}

.table-row-border {
    vertical-align: bottom !important;
    border-top: 3px solid variables.$adn-color-primary-brighter !important;
}

.adn-table-icon-column {
    width: 32px;
    white-space: nowrap;
}

tr.disabled>td {
    text-decoration: line-through;
    text-decoration-color: variables.$adn-color-primary;
}

.adn-nowrap {
    white-space: nowrap;
}

.grouped-icon-1 {
    left: 2px;
    top: 5px;
    position: relative;
    font-size: 1em;
}

.grouped-icon-2 {
    left: -6px;
    top: -5px;
    position: relative;
    font-size: 1em;
}

.grouped-icon-3 {
    left: -15px;
    top: 5px;
    position: relative;
    font-size: 1em;
}

.second-icon {
    left: -19px;
    top: -14px;
    position: relative;
    font-size: 0.8em;
}

.second-icon-label-adjustment {
    margin-left: -15px;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.right-extra-padding {
    padding-right: 25px !important;
}

.no-left-padding {
    padding-left: 0 !important;
}

table .adn-cell-buttons li.list-inline-item button.btn-light,
table .adn-cell-buttons button.btn-light {
    background-color: transparent;
    border-color: transparent;
}

/* remove scroll arrows on input type NUMBER */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

/* Content */

.adn-content-block {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1100px;
    margin-left: auto !important;
    margin-right: auto !important;
    word-break: break-word;
}

.adn-content>div.row {
    margin-left: -20px;
    margin-right: -20px;
}

.adn-content>.row>[class^="col-"],
.adn-content>.row>[class*=" col-"] {
    padding-left: 20px;
    padding-right: 20px;
}

.adn-content h1 {
    text-transform: uppercase;
}

.adn-content-navigation-container {
    background-color: variables.$adn-color-white;
    padding: 20px;
    line-height: 2rem;
}

.adn-content-navigation-content>ul {
    list-style: none;
    padding-inline-start: inherit;
}

.adn-content-navigation-header {
    font-weight: 700;
    font-size: larger;
}

.adn-content-dark-container {
    margin-top: 80px;
    margin-bottom: 80px;
    background-color: variables.$adn-color-primary;
    width: 100%;
}

.adn-content-dark-container h1,
.adn-content-dark-container h2,
.adn-content-dark-container h3,
.adn-content-dark-container h4,
.adn-content-dark-container p,
.adn-content-dark-container a {
    color: variables.$adn-color-white;
}

.adn-content-dusk-container {
    margin-top: 80px;
    margin-bottom: 80px;
    background-color: variables.$adn-color-dusk;
    width: 100%;
}

.adn-content-dusk-container h1,
.adn-content-dusk-container h2,
.adn-content-dusk-container h3,
.adn-content-dusk-container h4,
.adn-content-dusk-container p,
.adn-content-dusk-container a {
    color: variables.$adn-color-white;
}

.adn-hero-breadcrumb {
    font-size: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.adn-hero-breadcrumb>a:hover {
    text-decoration: none;
    color: variables.$adn-color-brand;
}

.adn-content-dark-background {
    background-color: variables.$adn-color-primary;
}

.adn-content-dark-background h1,
.adn-content-dark-background h2,
.adn-content-dark-background h3,
.adn-content-dark-background h4,
.adn-content-dark-background p,
.adn-content-dark-background a {
    color: variables.$adn-color-white;
}

.adn-content-dark-background a:hover {
    text-decoration: none;
    color: variables.$adn-color-brand;
}

.adn-content-dark-box {
    background-color: variables.$adn-color-primary;
    padding: 2rem;
    color: variables.$adn-color-white;
    margin-bottom: 2rem;
}

.adn-content-dark-box h1,
.adn-content-dark-box h2,
.adn-content-dark-box h3,
.adn-content-dark-box h4 {
    color: variables.$adn-color-white;
}

.adn-content-dark-box p {
    color: variables.$adn-color-white;
    line-height: 2;
}

.adn-content-dark-box a,
.adn-content-dark-box button.adn-cta-default {
    color: variables.$adn-color-white;
}

.adn-content-dark-box a:hover,
.adn-content-dark-box button.adn-cta-default:hover {
    text-decoration: none;
    color: variables.$adn-color-brand;
}

.adn-underline {
    text-decoration: underline;
}

.adn-content-dusk-box {
    background-color: variables.$adn-color-dusk;
    padding: 2rem;
    color: variables.$adn-color-white;
    margin-bottom: 2rem;
}

.adn-content-dusk-box h1,
.adn-content-dusk-box h2,
.adn-content-dusk-box h3,
.adn-content-dusk-box h4 {
    color: variables.$adn-color-white;
}

.adn-content-dusk-box p {
    color: variables.$adn-color-white;
    line-height: 2;
}

.adn-content-dusk-box a,
.adn-content-dusk-box button.adn-cta-default {
    color: variables.$adn-color-white;
}

.adn-content-dusk-box a:hover,
.adn-content-dusk-box button.adn-cta-default:hover {
    text-decoration: none;
    color: variables.$adn-color-brand;
}

.adn-content-background {
    background-color: variables.$adn-color-background;
    padding: 1.5rem 0;
}

@media only screen and (max-width: 428px) {
    .adn-content-background {
        padding: 0rem 0;
    }
}

.adn-content-background.form-min-height {
    min-height: min(35vw, 880px);
}

.adn-content-background h1,
.adn-content-background h2,
.adn-content-background h3,
.adn-content-background h4,
.adn-content-background p,
.adn-content-background a {
    color: variables.$adn-color-primary;
}

.adn-content-float-50 {
    width: 50%;
    height: 100%;
    float: left;
}

.adn-content-column {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.adn-image-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.img-fluid-left {
    max-width: 100%;
    height: auto;
}

.img-fluid-right {
    max-width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid-left {
        float: left;
        clear: both;
        max-width: 50%;
        margin-right: 8px;
    }

    .img-fluid-right {
        float: right;
        clear: both;
        max-width: 50%;
        margin-left: 8px;
    }

    .adn-content-block {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 768px) {
    .img-fluid-left {
        float: left;
        clear: both;
        max-width: 33%;
        margin-right: 16px;
    }

    .img-fluid-right {
        float: right;
        clear: both;
        max-width: 33%;
        margin-left: 16px;
    }
}

@media (min-width: 992px) {
    .img-fluid-left {
        float: left;
        clear: both;
        max-width: 25%;
        margin-right: 24px;
    }

    .img-fluid-right {
        float: right;
        clear: both;
        max-width: 25%;
        margin-left: 24px;
    }

    .adn-content-block {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    app-core-mega-search {
        padding-right: 12px;
        width: 200px;
    }
}

/* CTA */

.adn-cta-default {
    background: none;
    border: none;
    color: variables.$adn-color-primary;
    -webkit-appearance: none;
}

.adn-cta-default::after {
    content: "\00a0>";
}

.adn-cta-default:hover {
    color: variables.$adn-color-brand;
}

.adn-cta-primary {
    background: transparent;
    border: 1px solid variables.$adn-color-primary;
    box-shadow: 0 0 5px rgba(variables.$adn-color-primary-darker, 0.2);
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
    vertical-align: middle;
    color: variables.$adn-color-primary;
    border-radius: 4px;
}

.adn-cta-primary:hover {
    color: variables.$adn-color-brand;
}

.adn-cta-white {
    background: transparent;
    border: 1px solid variables.$adn-color-white;
    box-shadow: 0 0 5px rgba(variables.$adn-color-background, 0.2);
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
    vertical-align: middle;
    color: variables.$adn-color-white;
    border-radius: 4px;
}

.adn-cta-white:hover {
    color: variables.$adn-color-brand;
}

.adn-cta-accent {
    background: variables.$adn-color-accent;
    border: 1px solid variables.$adn-color-accent;
    box-shadow: 0 0 5px rgba(variables.$adn-color-accent, 0.2);
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
    vertical-align: middle;
    color: variables.$adn-color-white;
    border-radius: 4px;
}

.adn-cta-accent:hover {
    color: variables.$adn-color-brand;
}

.adn-cta-sky {
    background: variables.$adn-color-sky;
    border: 1px solid variables.$adn-color-white;
    box-shadow: 0 0 5px rgba(variables.$adn-color-background, 0.2);
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
    vertical-align: middle;
    color: variables.$adn-color-white;
    border-radius: 4px;
}

.adn-cta-sky:hover {
    color: variables.$adn-color-brand;
}

.adn-cta-red {
    background: variables.$adn-color-brand;
    border: 1px solid variables.$adn-color-brand;
    box-shadow: 0 0 5px rgba(variables.$adn-color-background, 0.2);
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
    vertical-align: middle;
    color: variables.$adn-color-white;
    border-radius: 4px;
}

/* Map */

.adn-map-fixed-ratio>agm-map {
    width: 100%;
    height: 42vh;
}

.adn-map-fixed-ratio {
    width: 100%;
    height: 42vh;
}

.adn-map-fixed-ratio>abm-map {
    width: 100% !important;
    height: 35vh !important;
}

.adn-map-fixed-ratio-60>agm-map {
    width: 100%;
    height: 60vh;
}

.adn-map-fixed-ratio-60 {
    width: 100%;
    height: 60vh;
}

.adn-map-fixed-ratio-60>abm-map {
    width: 100% !important;
    height: 60vh !important;
}

/* new field style  ------------------------------------------------------------------------------- */

/* form boxes */

.adn-form-standard {
    background-color: variables.$adn-color-background !important;
}

.adn-form-contrast {
    background-color: variables.$adn-color-white !important;
}

.adn-form-dark {
    background-color: variables.$adn-color-primary !important;
    color: variables.$adn-color-white !important;
}

.adn-form-field-no-label {
    border: 1px solid variables.$adn-color-bright-border;
    background-color: variables.$adn-color-white;
    display: inline-block;
    margin: 2px;
    padding: 2px;
    width: 100%;
}

.adn-form-field {
    border: 1px solid variables.$adn-color-bright-border;
    background-color: variables.$adn-color-white;
    display: inline-block;
    padding: 5px 5px 0px 5px;
    margin-top: 8px;
    width: 100%;
}

.adn-form-field-invalid {
    border: 1px solid variables.$adn-color-alert;
}

.adn-form-field-content {
    display: inline-flex;
    min-width: 50px;
    width: 100%;
}

.adn-form-field>label {
    flex: auto;
    min-width: 0;
    border: 0;
    box-shadow: none;
    color: variables.$adn-color-primary;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 12px;
    display: inline-block;
    margin-bottom: 0rem;
    background: linear-gradient(to bottom, variables.$adn-color-background 50%, variables.$adn-color-white 0%);
    padding: 0px 5px 0px 5px;
    max-width: 85%;
    position: absolute;
    top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adn-form-field-disabled {
    background: variables.$adn-color-background;
}

.adn-form-field-disabled>label {
    background: variables.$adn-color-background;
    color: variables.$adn-color-primary-brighter;
}

.adn-form-contrast .adn-form-field>label {
    background: variables.$adn-color-white;
}

.mat-card .adn-form-field>label,
.mat-dialog-container .adn-form-field>label {
    background: variables.$adn-color-white;
}

.adn-form-contrast .adn-form-field-disabled>label {
    background: linear-gradient(to bottom, variables.$adn-color-white 50%, variables.$adn-color-background 0%);
    color: variables.$adn-color-primary-brighter;
}

.mat-card .adn-form-field-disabled>label,
.mat-dialog-container .adn-form-field-disabled>label {
    background: linear-gradient(to bottom, variables.$adn-color-white 50%, variables.$adn-color-background 0%);
    color: variables.$adn-color-primary-brighter;
}

.adn-card-border-alert {
    border: 1.5px solid red !important;
}

.adn-card-title-alert {
    color: red !important;
}

.adn-form-dark .adn-form-field-disabled>label {
    background: linear-gradient(to bottom, variables.$adn-color-primary 50%, variables.$adn-color-background 0%);
    color: variables.$adn-color-primary-brighter;
}

.adn-form-dark .adn-form-field>label {
    background: variables.$adn-color-white;
}

.adn-form-field-invalid>label {
    color: variables.$adn-color-alert;
}

app-form-field-display>div>div {
    padding-top: 2px;
    padding-left: 5px;
    padding-bottom: 2px;
}

.adn-form-field-hint {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 12px;
}

.adn-form-field-content app-core-progress {
    width: 100%;
}

.adn-form-field-check-box {
    margin-top: 3px;
    margin-bottom: -5px;
    margin-right: 1em;
}

/* text input boxes */

.input-style {
    flex: auto;
    min-width: 0;
    border: 0;
    box-shadow: none;
    padding-top: 2px;
    padding-left: 5px;
    padding-bottom: 2px;
    background-color: rgb(0, 0, 0, 0);
}

.text-right {
    text-align: right;
}

.input-style:focus {
    outline: 0px !important;
}

.number-input-style {
    flex: auto;
    min-width: 0;
    border: 0;
    text-align: right;
    box-shadow: none;
    padding-top: 2px;
    padding-left: 5px;
    padding-bottom: 2px;
    background-color: rgb(0, 0, 0, 0);
}

.number-input-style:focus {
    outline: 0px !important;
}

.input-prefix {
    white-space: nowrap;
    font-size: 16px;
}

.input-suffix {
    white-space: nowrap;
    font-size: 16px;
}

/* clickable clear, hint, etc icons */

.input-icon .mat-icon-button {
    color: variables.$adn-color-primary;
    display: inline-block !important;
    height: 1.5em !important;
    width: 1.5em !important;
    line-height: 0px;
    padding-top: 0px;
    margin-top: 2px;
}

/* error messages */

.adn-form-field-error {
    padding-left: 5px;
    margin-top: -5px;
}

.error-icon {
    color: variables.$adn-color-alert;
    font-size: 10px;
}

.error-text {
    color: variables.$adn-color-alert;
    font-size: 12px;
}

/* ng-bootstrap styles */

.dropdown-item {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: variables.$adn-color-primary;
    text-align: inherit;
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
    background-color: transparent;
    border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: variables.$adn-color-primary;
    text-decoration: none;
    background-color: variables.$adn-color-background;
}

.dropdown-item.active,
.dropdown-item:active {
    color: variables.$adn-color-background;
    text-decoration: none;
    background-color: variables.$adn-color-primary;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: variables.$adn-color-disabled;
    background-color: transparent;
}

.dropdown-menu {
    width: fit-content;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: variables.$adn-color-primary-brighter;
    white-space: nowrap;
}

.ngb-datepicker-background-white {
    background-color: variables.$adn-color-white;
}

.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    border-radius: 0.25rem;
    display: inline-block;
    width: 2rem;
}

.custom-day:hover {
    background-color: variables.$adn-color-disabled;
}

.selected-day {
    border: 1px solid variables.$adn-color-primary;
    background-color: variables.$adn-color-primary;
    color: variables.$adn-color-white;
}

.today-day {
    border: 1px solid variables.$adn-color-primary;
}

.disabled-day {
    color: variables.$adn-color-disabled;
}

.ngbDropdown-toggle {
    color: variables.$adn-color-primary;
    font-size: 14px;
}

/* loading spinner  */

.adn-input-icon-spinner-container {
    display: inline-block !important;
    height: 1.5em !important;
    width: 1.5em !important;
    line-height: 0px;
    padding-top: 3px;
    margin-top: 2px;
    padding-right: 3px;
}

.adn-input-icon-spinner-loader {
    position: relative;
    height: 15px;
    width: 15px;
    display: inline-block;
    animation: spinner-around 5.4s infinite;
}

@keyframes spinner-around {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.adn-input-icon-spinner-loader::after,
.adn-input-icon-spinner-loader::before {
    content: "";
    background: transparent;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: variables.$adn-color-disabled variables.$adn-color-disabled transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
}

.adn-input-icon-spinner-loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
}

.ngx-pagination {
    margin-left: -2.5rem !important;
}

.ngx-pagination .current {
    background: variables.$adn-color-accent !important;
}

pre {
    font-family: "IBM Plex Sans", sans-serif;
}

.adn-scrollable-content {
    text-align: left;
    max-height: 650px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 1.5rem;
}

@media (max-width: 991px) {
    .adn-scrollable-content {
        margin: initial;
        max-height: inherit;
        overflow-x: inherit;
        overflow-y: inherit;
    }
}

.adn-flex-container {
    display: flex;
    flex-wrap: wrap;
    font-size: 30px;
    text-align: center;
}

legend {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: unset;
}

.mat-radio-group {
    margin: 5px 0 0 0;
    height: 20px;
}

.mat-radio-label-content {
    padding-right: 18px !important;
    font-size: 14px;
}

hr {
    margin: 20px auto 30px auto;
}

hr.thin {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.countryGroup .mat-tab-label {
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.2;
}

.mat-cell ul {
    padding-left: 0;
}

.mat-cell li.list-inline-item {
    float: left;
}